import React, { createContext, useState, useContext } from 'react';

const MembroLogadoContext = createContext();

function MembroLogadoProvider({ children }) {
  const [membroLogado, setMembroLogado] = useState({
    active: '',
    cargo: [{
      active: '',
      descricao: '',
      id: '',
      name: '',
      resumo: '',
    }],
    data_nascimento: '',
    email: '',
    email_verified_at: '',
    formacao: '',
    foto_perfil: '',
    id: '',
    id_cargo: '',
    id_setor: '',
    last_access: '',
    name: '',
    resumo_experiencia: '',
    role: '',
    send_last_invite: '',
    setor: [{
      active: '',
      descricao: '',
      id: '',
      name: '',
    }],
    telefone: '',
    url_linkedin: '',
  });

  return (
    <MembroLogadoContext.Provider
      value={{
        membroLogado,
        setMembroLogado,
      }}
    >
      {children}
    </MembroLogadoContext.Provider>
  );
};

function useMembroLogado() {
  const context = useContext(MembroLogadoContext);
  const {
    membroLogado, setMembroLogado,
  } = context;
  return {
    membroLogado, setMembroLogado,
  };
};

export { MembroLogadoProvider, useMembroLogado };
