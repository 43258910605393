import React, { createContext, useState, useContext } from 'react';

const TopBarActionsContext = createContext();

function TopBarActionsProvider({ children }) {
  const [menuReduzido, setMenuReduzido] = useState(true);

  return (
    <TopBarActionsContext.Provider
      value={{ menuReduzido, setMenuReduzido }}
    >
      {children}
    </TopBarActionsContext.Provider>
  );
};

function useTopBarActions() {
  const context = useContext(TopBarActionsContext);
  const {
    menuReduzido, setMenuReduzido,
  } = context;
  return {
    menuReduzido, setMenuReduzido,
  };
};

export { TopBarActionsProvider, useTopBarActions };
