import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

import { useToast } from '../../../hooks/ToastContext';
import { Container } from './styles';

function Toast({ message, style }) {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [removeToast, message.id]);

  return (
    <Container type={message.type} hasDescription={!!message.description} style={style}>

      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <FaTimes size={16} />
      </button>
    </Container>
  );
}

export default Toast;