import { combineReducers } from "redux";

import toast from "./toast/reducer";
import auth from "./auth/reducer";
import member from "./member/reducer";
import breadcrumbs from "./breadcrumbs/reducer";
import organizacao from "./organizacao/reducer";

export default combineReducers({
  toast,
  member,
  auth,
  breadcrumbs,
  organizacao
});
