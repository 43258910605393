import React from "react";

import PageContent from "../PageContent";
import { store } from "../../store";
import PageToast from "../PageToast";

export default function PageWrap({ children, ...rest }) {
  const { authenticated } = store.getState().auth;

  return (
    <>
      <PageToast />
      {authenticated ? (
        <>
          <PageContent>{children}</PageContent>
        </>
      ) : (
        children
      )}
    </>
  );
}
