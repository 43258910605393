import produce from "immer";

import { MemberType } from "./actions";

export const INITIAL_STATE = {
  photo: null,
  user: {},
  members: [],
};

export default function member(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case MemberType.USER: {
        draft.photo = action.payload.photo;
        draft.user = action.payload.user;
        break;
      }
      case MemberType.SET_LIST: {
        draft.members = action.payload.list;
        break;
      }
      default:
    }
  });
}
