export const AuthTypes = {
  LOGIN: "@AUTH/LOGIN",
  SET_LOGIN: "@AUTH/SET_LOGIN",
  LOGOUT: "@AUTH/LOGOUT",
  UPDATE_USER: "@AUTH/UPDATE_USER",
  SET_USER_DATA: "@AUTH/SET_USER_DATA",
  SET_PASSWORD: "@AUTH/SET_PASSWORD",
  SET_PASSWORD_PROFILE: "@AUTH/SET_PASSWORD_PROFILE",
  VALIDATE_TOKEN: "@AUTH/VALIDATE_TOKEN",
  CREATE_INVITE_USER: "@AUTH/CREATE_INVITE_USER",
  SET_USER_SESSION: "@AUTH/SET_USER_SESSION",
  ATT_TOKEN: "@AUTH/ATT_TOKEN",
  REQUEST_RESET_PASSWORD: "@AUTH/REQUEST_RESET_PASSWORD",
  RESET_PASSWORD: "@AUTH/RESET_PASSWORD",
  ADD_EXPIRATION: "@AUTH/ADD_EXPIRATION",
};

export function login(email, password) {
  return {
    type: AuthTypes.LOGIN,
    payload: {
      email,
      password,
    },
  };
}

export function setLogin(user, token) {
  return {
    type: AuthTypes.SET_LOGIN,
    payload: {
      user,
      token,
    },
  };
}

export function logout() {
  return {
    type: AuthTypes.LOGOUT,
  };
}

export function updateUser(dados) {
  return {
    type: AuthTypes.UPDATE_USER,
    payload: {
      dados,
    },
  };
}

export function setUserData(data) {
  return {
    type: AuthTypes.SET_USER_DATA,
    payload: {
      data,
    },
  };
}

export function setPassword(password) {
  return {
    type: AuthTypes.SET_PASSWORD_PROFILE,
    payload: {
      password,
    },
  };
}

export function setPasswordProfile(id, password) {
  return {
    type: AuthTypes.SET_PASSWORD_PROFILE,
    payload: {
      id,
      password,
    },
  };
}

export function validateToken(token, redirect = "/cadastro") {
  return {
    type: AuthTypes.VALIDATE_TOKEN,
    payload: {
      token,
      redirect,
    },
  };
}

export function userToAuth(user) {
  return {
    type: AuthTypes.SET_USER_SESSION,
    payload: {
      user,
    },
  };
}

export function confirmNewUser(name, password, user_id) {
  return {
    type: AuthTypes.CREATE_INVITE_USER,
    payload: {
      name,
      password,
      user_id,
    },
  };
}

export function attToken() {
  return {
    type: AuthTypes.ATT_TOKEN,
  };
}

export function resetPassword(email) {
  return {
    type: AuthTypes.REQUEST_RESET_PASSWORD,
    payload: {
      email,
    },
  };
}

export function createNewPassword(password, user) {
  return {
    type: AuthTypes.RESET_PASSWORD,
    payload: {
      password,
      user,
    },
  };
}

export function addExpiration(now) {
  return {
    type: AuthTypes.ADD_EXPIRATION,
    payload: {
      now,
    },
  };
}
