import React, { createContext, useState, useContext } from 'react';

const MembroInfosContext = createContext();

function MembroInfosProvider({ children }) {
  const [membroInfos, setMembroInfos] = useState([]);

  return (
    <MembroInfosContext.Provider
      value={{
        membroInfos,
        setMembroInfos,
      }}
    >
      {children}
    </MembroInfosContext.Provider>
  );
};

function useMembroInfos() {
  const context = useContext(MembroInfosContext);
  const {
    membroInfos, setMembroInfos,
  } = context;
  return {
    membroInfos, setMembroInfos,
  };
};

export { MembroInfosProvider, useMembroInfos };
