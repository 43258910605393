import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

import { useToast } from './ToastContext';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { addToast } = useToast();

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Aligna:accessToken');
    const user = localStorage.getItem('@Aligna:loggedUser');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {

    try {

      const response = await api.post('/login', {
        email,
        password,
      });
  
      const { token, user } = response.data;
      
      localStorage.setItem('@Aligna:accessToken', token);
      localStorage.setItem('@Aligna:loggedUser', JSON.stringify(user))

      setData({ token, user });
      
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Credenciais inválidas',
      });

      return
    }

  }, [addToast]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Aligna:accessToken');
    localStorage.removeItem('@Aligna:loggedUser');

    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
