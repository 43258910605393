import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import {
  AuthTypes,
  setLogin,
  setOrganization,
  setUserData,
  userToAuth,
  logout as logoutAction,
  addExpiration,
} from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { store } from "../../../store";

export function* login({ payload }) {
  const { email, password } = payload;

  const response = yield call(api, "post", "/admin/login", { email, password });

  const { data, status } = response;
  switch (status) {
    case 200:
      /*var optionsOrganizacoes = [];
      
      data && data.user.organizacoes.map((organizacao) => {
        if (organizacao.active) {
          optionsOrganizacoes.push({value: organizacao.id, label: organizacao.name, isFixed: true});
        }
      })*/

      yield put(setLogin(data.user, data.token));
      history.push("/pop");
      break;
    case 401:
      yield put(
        addToast({
          title: "Login ou Senha inválidos.",
          type: "danger",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "Sem Permissão",
          type: "danger",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      //yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* logout() {
  history.push("/");
  window.localStorage.clear();
  history.go("/");
}

export function* updateUser({ payload }) {
  const { dados } = payload;
  const { id } = store.getState().auth.user;

  const response = yield call(api, "post", `/member/${id}`, dados);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(setUserData(data.data));
      history.push("/membros");
      yield put(
        addToast({
          title: "Dados atualizados com sucesso.",
          type: "success",
        })
      );
      break;
    case 401:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* setPassword({ payload }) {
  const { password } = payload;

  const response = yield call(api, "post", `/member/newPassword`, { password });

  const { data, status } = response;
  switch (status) {
    case 200:
      break;
    case 401:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* setPasswordProfile({ payload }) {
  const { id, password } = payload;

  const response = yield call(api, "post", `/member/${id}/newPasswordProfile`, {
    password,
  });

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Senha alterada com sucesso.",
          type: "success",
        })
      );
      break;
    case 401:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;

    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* validateToken({ payload }) {
  const { token, redirect } = payload;

  const response = yield call(api, "get", `/member/confirm/${token}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(userToAuth(data.user));
      history.push(redirect);
      break;

    case 422:
      yield put(
        addToast({
          title: "Link expirado",
          type: "danger",
        })
      );
      history.push("/invalido");
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* createInviteUser({ payload }) {
  const { name, password, user_id } = payload;

  const response = yield call(api, "post", `/member/confirm/password`, {
    name,
    password,
    user_id,
  });

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(userToAuth({}));
      history.push("/");
      break;
    case 422:
      yield put(
        addToast({
          title: "Algo deu errado no seu cadastro, verifique seus dados.",
          type: "danger",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* attToken({ payload }) {
  const { id } = store.getState().auth.user;
  const response = yield call(api, "get", `/member/new/token/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(setLogin(data.user, data.token));
      window.location.reload(true);
      break;
    case 422:
      yield put(
        addToast({
          title: "Algo deu errado no seu cadastro, verifique seus dados.",
          type: "danger",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* requestResetPassword({ payload }) {
  const { email } = payload;
  const response = yield call(api, "post", `/member/new/password/${email}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      history.push("/email-enviado");

      break;

    case 422:
      yield put(
        addToast({
          title: "Usuário não encontrado.",
          type: "danger",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* resetPassword({ payload }) {
  const { password, user } = payload;

  const response = yield call(api, "post", `/member/set/password/`, {
    password,
    user,
  });

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(userToAuth({}));
      history.push("/");
      yield put(
        addToast({
          title: "Senha alterada com sucesso.",
          type: "success",
        })
      );
      break;
    case 401:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logoutAction());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;

    case 422:
      yield put(
        addToast({
          title: "Ocorreu algum problema ao alterar a senha.",
          type: "danger",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logoutAction());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

function verificarValidade() {
  const { expires_in } = store.getState().auth;
  let now = new Date().getTime();
  if (!expires_in) {
    store.dispatch(addExpiration(now));
  }
  var regex = /'\/'/gm;
  if (regex.exec(window.location.href)) {
    store.dispatch(addExpiration(now));
    return false;
  }

  if (store.getState().auth.expires_in < now - 60000) {
    store.dispatch(addExpiration(now));
    // store.dispatch(logoutAction());
  } else {
    store.dispatch(addExpiration(now));
  }
}

// eslint-disable-next-line require-yield
export function* checarValidade() {
  verificarValidade();
  setInterval(() => {
    verificarValidade();
  }, 20000);
}

export default all([
  takeLatest(AuthTypes.LOGIN, login),
  takeLatest(AuthTypes.LOGOUT, logout),
  takeLatest(AuthTypes.UPDATE_USER, updateUser),
  takeLatest(AuthTypes.SET_PASSWORD, setPassword),
  takeLatest(AuthTypes.SET_PASSWORD_PROFILE, setPasswordProfile),
  takeLatest(AuthTypes.VALIDATE_TOKEN, validateToken),
  takeLatest(AuthTypes.CREATE_INVITE_USER, createInviteUser),
  takeLatest(AuthTypes.ATT_TOKEN, attToken),
  takeLatest(AuthTypes.REQUEST_RESET_PASSWORD, requestResetPassword),
  takeLatest(AuthTypes.RESET_PASSWORD, resetPassword),
  takeLatest("persist/REHYDRATE", checarValidade),
]);
