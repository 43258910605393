export const MemberType = {
  ALL: "@MEMBER/ALL",
  SET_LIST: "@MEMBER/SET_LIST",
  FIND: "@MEMBER/FIND",
  USER: "@MEMBER/USER",
  INVITE: "@MEMBER/INVITE",
};

export function getAll() {
  return {
    type: MemberType.ALL,
  };
}

export function setList(list) {
  return {
    type: MemberType.SET_LIST,
    payload: {
      list,
    },
  };
}

export function getMembers(id) {
  return {
    type: MemberType.FIND,
    payload: {
      id,
    },
  };
}


export function setMember(user, photo) {
  return {
    type: MemberType.USER,
    payload: {
      user,
      photo,
    },
  };
}

export function sendInvite(id) {
  return {
    type: MemberType.INVITE,
    payload: {
      id,
    },
  };
}