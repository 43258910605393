import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import {
  OrganizacaoTypes,
  add,
  setOptionsOrgs,
  addById,
  get as getAllOrganizacoes,
} from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { store } from "../../../store";
import { logout } from "../auth/actions";

export function* get({ payload }) {
  const { search, filterStatus, filterCategoria, setLoading } = payload;

  const response = yield call(
    api,
    "get",
    "admin/organizacoes",
    {},
    { search, filterStatus, filterCategoria }
  );

  const { data, status } = response;

  switch (status) {
    case 200:
      const optionOrgs = [];
      if (data.length > 0) {
        data.map(org => {
          if (org.active) {
            optionOrgs.push({ value: org.id, label: org.name, isFixed: true });
          }
        });
      }

      yield put(setOptionsOrgs(optionOrgs));
      yield put(add(data));

      if (setLoading !== undefined) {
        setLoading(false);
      }
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getById({ payload }) {
  const { id, setLoading } = payload;

  const response = yield call(api, "get", `admin/organizacoes/${id}`, {}, {});

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(addById(data));

      if (setLoading !== undefined) {
        setLoading(false);
      }
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: "Organização não encontrada.",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* create({ payload }) {
  const { organizacao } = payload;

  const response = yield call(api, "post", `admin/organizacoes/`, organizacao);

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Organização criada com sucesso.",
          type: "success",
        })
      );
      history.push("/organizacoes");
      break;
    case 201:
      yield put(
        addToast({
          title: "Organização criada com sucesso.",
          type: "success",
        })
      );
      history.push("/organizacoes");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: `Organização com nome ${organizacao.name} já cadastrada.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: "Dados inválidos:",
          text: data,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, organizacao } = payload;
  const response = yield call(
    api,
    "post",
    `admin/organizacoes/${id}`,
    organizacao
  );

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      history.push(`/organizacao/${id}`);
      yield put(
        addToast({
          title: "Dados atualizados com sucesso.",
          type: "success",
        })
      );
      yield put(getAllOrganizacoes());
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Organização com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: `Organização com nome ${organizacao.name} já cadastrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* updateStatus({ payload }) {
  const { id, organizacao } = payload;
  const response = yield call(
    api,
    "put",
    `admin/organizacoes/${id}`,
    organizacao
  );

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      history.push(`/organizacoes/`);
      yield put(
        addToast({
          title: "Status atualizado com sucesso.",
          type: "success",
        })
      );
      yield put(getAllOrganizacoes());
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Organização com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteOrganizacao({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `admin/organizacoes/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
          title: "Organização excluída com sucesso",
          type: "success",
        })
      );
      history.push("/organizacoes");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Organização com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(OrganizacaoTypes.GET, get),
  takeLatest(OrganizacaoTypes.GET_BY_ID, getById),
  takeLatest(OrganizacaoTypes.CREATE, create),
  takeLatest(OrganizacaoTypes.UPDATE, update),
  takeLatest(OrganizacaoTypes.UPDATE_STATUS, updateStatus),
  takeLatest(OrganizacaoTypes.DELETE, deleteOrganizacao),
]);
