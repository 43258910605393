import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  body {
    padding-top: 0px;
    background: #F3F7F9!important;
    color: #3A525A;
    -webkit-font-smoothing: antialiased;
  }


  body, input, textarea, select, button, a {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  .rodape-footer {
    height: 44px;
    width: 100%;
    padding: 10px 30px;
    bottom: 0px;
    background-color: white;
    border-top: 1px solid #e4eaec;
    z-index: -1;
    font-family: Roboto,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.57142857;
    color: #76838f;
    text-align: left;
}
  }

  .site-footer-legal {
    margin-left: 100px;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }

  select {
    background: white;
  }

  .nav-item {
    cursor: pointer;
  }

  .clickable {
    cursor: pointer;
  }

  .panel-padding {
    background: #F3F7F9;
    padding: 30px;

    @media (max-width: 500px) {
      padding: 10px;
    }
  }

  .default-title{
    color: #37474f;
    font-size: 18px;
    line-height: 21px;

  }

  .default-subtitle{
    color: #76838f;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }
`;
