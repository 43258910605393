import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const toastTypeVariations = {
  info: css`
    background: #0073FC;
    color: #fff;
  `,
  success: css`
    background: #07bc0c;
    color: #fff;
  `,
  error: css`
    background: #e74c3c;
    color: #fff;
  `,
};

export const Container = styled(animated.div)`
  width: 312px;

  position: relative;
  padding: 16px 30px 16px 16px;
  height: 64px;
  /* border-radius: 10px; */
  /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); */

  margin: 30px;

  display: flex;

  & + div {
    margin-top: 8px;
  }
  
  ${(props) => toastTypeVariations[props.type || 'info']};

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      margin-bottom: 0;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 10px;
    top: 10px;
    border: 0;
    background: transparent;
    color: inherit;
  }

  @media (max-width: 500px) {
    width: 300px;
  }

  ${props => !props.hasDescription && css`
    align-items: center;

    svg {
      margin-top: 0;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`;
