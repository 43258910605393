import React, { createContext, useState, useContext } from 'react';

const EmailEnviadoContext = createContext();

function EmailEnviadoProvider({ children }) {
  const [emailEnviado, setEmailEnviado] = useState('');

  return (
    <EmailEnviadoContext.Provider
      value={{
        emailEnviado,
        setEmailEnviado,
      }}
    >
      {children}
    </EmailEnviadoContext.Provider>
  );
};

function useEmailEnviado() {
  const context = useContext(EmailEnviadoContext);
  const {
    emailEnviado, setEmailEnviado,
  } = context;
  return {
    emailEnviado, setEmailEnviado,
  };
};

export { EmailEnviadoProvider, useEmailEnviado };
