import './configs/ReactotronConfig';

import React from 'react';

import "./themes/global/css/bootstrap.min.css";
import "./themes/global/css/bootstrap-extend.min.css";
import "./themes/base/assets/css/site.min.css";
import 'antd/dist/antd.css';
import $ from 'jquery';
import 'bootstrap';

import GlobalStyle from './styles/global';

import AppProvider from './hooks/index';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';

import { store, persistor } from './store';
import Routes from './routes';
import history from './services/history';

window.jQuery = $;
function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <PersistGate persistor={persistor}>
          <GlobalStyle />
          <Router history={history}>
            <Routes />
          </Router>
        </PersistGate>
      </AppProvider>
  </Provider>
  );
}

export default App;
