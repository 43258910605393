export const ToastTypes = {
  ADD_TOAST: '@TOAST/ADD_TOAST',
  REMOVE_TOAST: '@TOAST/REMOVE_TOAST',
};

export function addToast(dados) {
  return {
    type: ToastTypes.ADD_TOAST,
    payload: dados,
  };
}

export function removeToast(id) {
  return {
    type: ToastTypes.REMOVE_TOAST,
    payload: id,
  };
}
