import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "../../../services/api";
import { MemberType, setMember, setList } from "./actions";
import { logout } from "../auth/actions";
import { addToast } from "../../../store/modules/toast/actions";

export function* getAll() {
  const response = yield call(api, "get", `admin/member`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(setList(data.data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
  return;
}

export function* find({ payload }) {
  const response = yield call(api, "get", `admin/member/${payload.id}`);
  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(setMember(data.data.user, data.data.user.foto_perfil || null));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* invite({ payload }) {
  const { id } = payload;

  const response = yield call(api, "post", `member/invite/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "E-mail reenviado com sucesso.",
          type: "success",
        })
      );
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(MemberType.ALL, getAll),
  takeLatest(MemberType.FIND, find),
  takeLatest(MemberType.INVITE, invite),
]);
