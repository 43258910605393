export const BreadcrumbsTypes = {
  ADD_PATH: "@BREAD/ADD_PATH",
};

export function addPath(path) {
  return {
    type: BreadcrumbsTypes.ADD_PATH,
    payload: {
      path
    }
  };
}

