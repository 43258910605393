import React from 'react';
import { Route } from 'react-router-dom';

import PageWrap from '../components/PageWrap';
import { useDispatch } from "react-redux";
import { logout } from "../store/modules/auth/actions";
import { store } from "../store";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {

  let { authenticated } = store.getState().auth;
  const dispatch = useDispatch();
  
  if (!authenticated && isPrivate) {
    dispatch(logout());
    return (
      <>Não está logado</>
    );
  }

  return (
    <PageWrap {...rest}>
      <Route {...rest} component={Component} />
    </PageWrap>
  );
}

