import produce from "immer";

import { AuthTypes } from "./actions";

export const INITIAL_STATE = {
  token: false,
  user: {},
  user_to_auth: {},
  authenticated: false,
  expires_in: undefined,
  //optionsOrganizacoes: []
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case AuthTypes.SET_LOGIN: {
        draft.token = action.payload.token;
        draft.user = action.payload.user;
        //draft.optionsOrganizacoes = action.payload.optionsOrganizacoes;
        draft.authenticated = true;
        break;
      }
      case AuthTypes.LOGOUT: {
        draft.token = false;
        draft.user = {};
        draft.authenticated = false;
        break;
      }
      case AuthTypes.SET_USER_DATA: {
        draft.user = action.payload.data;
        break;
      }
      case AuthTypes.SET_USER_SESSION: {
        draft.user_to_auth = action.payload.user;
        break;
      }
      case AuthTypes.ADD_EXPIRATION: {
        draft.expires_in = action.payload.now;
        break;
      }
      default:
    }
  });
}
