export const OrganizacaoTypes = {
  GET: "@Organizacao/GET",
  SET_OPTIONS_ORGS: "@Organizacao/SET_OPTIONS_ORGS",
  ADD: "@Organizacao/ADD",
  GET_BY_ID: "@Organizacao/GET_BY_ID",
  ADD_BY_ID: "@Organizacao/ADD_BY_ID",
  CREATE: "@Organizacao/CREATE",
  UPDATE: "@Organizacao/UPDATE",
  UPDATE_STATUS: "@Organizacao/UPDATE_STATUS",
  DELETE: "@Organizacao/DELETE",
};

export function get(search, filterStatus, filterCategoria, setLoading) {
  return {
    type: OrganizacaoTypes.GET,
    payload: {
      search,
      filterStatus,
      filterCategoria,
      setLoading,
    },
  };
}

export function add(organizacoes) {
  return {
    type: OrganizacaoTypes.ADD,
    payload: {
      organizacoes,
    },
  };
}

export function setOptionsOrgs(options) {
  return {
    type: OrganizacaoTypes.SET_OPTIONS_ORGS,
    payload: {
      options,
    },
  };
}

export function getById(id, setLoading) {
  return {
    type: OrganizacaoTypes.GET_BY_ID,
    payload: {
      id,
      setLoading,
    },
  };
}

export function addById(organizacao) {
  return {
    type: OrganizacaoTypes.ADD_BY_ID,
    payload: {
      organizacao,
    },
  };
}

export function create(organizacao) {
  return {
    type: OrganizacaoTypes.CREATE,
    payload: {
      organizacao,
    },
  };
}

export function update(id, organizacao) {
  return {
    type: OrganizacaoTypes.UPDATE,
    payload: {
      id,
      organizacao,
    },
  };
}

export function updateStatus(id, organizacao) {
  return {
    type: OrganizacaoTypes.UPDATE_STATUS,
    payload: {
      id,
      organizacao,
    },
  };
}

export function deleteOrganizacao(id) {
  return {
    type: OrganizacaoTypes.DELETE,
    payload: {
      id,
    },
  };
}
