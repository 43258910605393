import React from 'react';

import { AuthProvider } from './AuthContext';
import { ToastProvider } from './ToastContext';
import { TopBarActionsProvider } from './TopBarActionsContext';
import { MembroInfosProvider } from './MembroInfosContext';
import { MembroLogadoProvider } from './MembroLogadoContext';
import { EmailEnviadoProvider } from './EmailEnviadoContext';

const AppProvider = ({ children }) => (
  <ToastProvider>
  <AuthProvider>
    <TopBarActionsProvider>
    <MembroInfosProvider>
    <MembroLogadoProvider>
    <EmailEnviadoProvider>
      {children}
    </EmailEnviadoProvider>
    </MembroLogadoProvider>
    </MembroInfosProvider>
    </TopBarActionsProvider>
  </AuthProvider>
  </ToastProvider>
)

export default AppProvider;
