import produce from 'immer';

import { BreadcrumbsTypes } from './actions';

export const INITIAL_STATE = {
  path: [],
};

export default function breadcrumbs(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case BreadcrumbsTypes.ADD_PATH: {
        draft.path = action.payload.path;
        break;
      }
      default:
    }
  });
}
