const routes = [
  {
    title: "Login",
    component: "Login",
    path: "/",
    auth: false,
    exact: true,
  },
  {
    title: "Esqueceu Senha",
    component: "EsqueceuSenha",
    path: "/esqueceu-senha",
    auth: false,
    exact: true,
  },
  {
    title: "Email Enviado",
    component: "EmailEnviado",
    path: "/email-enviado",
    auth: false,
    exact: true,
  },
  {
    title: "Redefinir Senha",
    component: "RedefinirSenha",
    path: "/redefinir-senha",
    auth: false,
    exact: true,
  },
  {
    title: "Criar Senha",
    component: "CriarSenha",
    path: "/criar-senha",
    auth: false,
    exact: true,
  },
  {
    title: "Completar Cadastro",
    component: "CompletarCadastro",
    path: "/completar-cadastro",
    auth: true,
    exact: true,
  },
  {
    title: "Organizacao",
    component: "Organizacao",
    path: "/organizacoes",
    auth: true,
    exact: true,
  },
  {
    title: "Cadastrar Organizacao",
    component: "CadastrarOrganizacao",
    path: "/organizacao",
    auth: true,
    exact: true,
  },
  {
    title: "Exibir Organizacao",
    component: "ExibirOrganizacao",
    path: "/organizacao/:id",
    auth: true,
    exact: true,
  },
  {
    title: "Editar Organizacao",
    component: "EditarOrganizacao",
    path: "/organizacao/:id/editar",
    auth: true,
    exact: true,
  },
  {
    title: "Confirmar Token",
    component: "ConfirmarToken",
    path: "/confirmar/:token",
    auth: false,
    exact: true,
  },
  {
    title: "Reset Token",
    component: "ResetToken",
    path: "/reset/:token",
    auth: false,
    exact: true,
  },
  {
    title: "Token Inválido",
    component: "TokenInvalido",
    path: "/invalido/",
    auth: false,
    exact: true,
  },
  {
    title: "Cadastro",
    component: "CadastroPorToken",
    path: "/cadastro/",
    auth: false,
    exact: true,
  },
  {
    title: "Resetar",
    component: "ResetarPorToken",
    path: "/resetar/",
    auth: false,
    exact: true,
  },
];

export default routes;
